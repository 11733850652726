<template lang="pug">
div(fluid v-if='Contacto_mapa').pa-0
    .d-none.d-md-block
        v-img(  height='100%' :src='Contacto_mapa.imagen' contain ).mt-6 
        
            .contenedor-cotizacion(v-scrollanimation).gris
                .columna-texto
                    v-img(  :src='Contacto_mapa.imagen_texto'  height='80px' contain)
                    p(v-html='Contacto_mapa.subtext').text-center.subtexto2
                .columna-contacto.align-items-center
                    v-form(v-model='validación')
                        v-text-field(placeholder='* Nombres y Apellidos:' v-model='información["Nombre: "]' )
                        v-text-field(placeholder='* Correo electrónico:' v-model='información["Correo: "]' )
                        v-text-field(placeholder='* Teléfono:' v-model='información["Celular: "]' )
                        v-textarea( hide-details  v-model='información["Mensaje: "]' outlined flat solo label='Mensaje:' ).forma_mensaje
                        .fila-boton.justify-center.align-items-center
                            v-btn(@click='enviar()' :loading='enviando_correo'   :disabled='!validación' color='#00AA7F').elevation-0
                                h3.white--text(style="text-transform: capitalize; ").font-italic  Enviar

    .d-md-none
        v-img(  height='100%' :src='Contacto_mapa.movil' contain )

            v-container(fluid)(v-scrollanimation).gris
                v-row.justify-center.align-center(v-if='Citas')
                    v-col(cols=8 style="margin-top: 40%")
                        
                        v-form(v-model='validación').pa-6
                            v-text-field(placeholder='* Nombres y Apellidos:' v-model='información["Nombre: "]')
                            v-text-field(placeholder='* Correo electrónico:' v-model='información["Correo: "]' )
                            v-text-field(placeholder='* Teléfono:' v-model='información["Celular: "]' )
                            v-textarea( hide-details v-model='información["Mensaje: "]'  outlined flat solo label='Mensaje:' ).forma_mensaje
                        v-row.justify-center.align-center   
                            v-btn(@click='enviar()' :loading='enviando_correo' :disabled='!validación' color='#00AA7F').elevation-0
                                h4.white--text(style="text-transform: capitalize; ").font-italic  Enviar
                
    
    //- MAPA
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.208490581055!2d-77.01529175220196!3d-12.097874757663748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9242f881031%3A0x4037c18312ba7746!2sUroavanza!5e0!3m2!1ses-419!2spe!4v1681703601301!5m2!1ses-419!2spe" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   
</template>
<script>
    import { mapActions, mapState } from 'vuex'
    export default {
        data: ()=>({
            información: {},
            enviando_correo: false,
            validación: true,
        }),
        methods: {
         
            ...mapActions({
                enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
                activar_snackbar: 'Ayudas/activar_snackbar',
            }),
            enviar(){
                const titulo = 'Información del contacto'
                const asunto = `Nuevo contacto`
                let cuerpo = `
                <table>`
                Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
                cuerpo += `</table>`
                const configuracion_de_correo = {
                        auth: {
                            user: 'admin@uroavanza.com',
                            pass: 'Uroavanza_123'
                        },
                        port: 465,
                        secure: true,
                        host: 'smtp.zoho.com' 
                }
                const correo_electronico = 'informes@uroavanza.com'
                this.enviando_correo = true
                this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
                .then( () => { 
                    this.activar_snackbar('Gracias. Nos pondremos en contacto.')
                    this.enviando_correo = false
                    this.información  = {}
                })
            },
        },
        computed: {
            ...mapState({
                Reglas: ({Ayudas})=>Ayudas.reglas,
            }),
        }
    }
</script>
<style lang="sass" scoped>

.contenedor-cotizacion
 
  display: flex
  flex-direction: row
  .columna-texto
    flex-direction: column
    width: 50%
    padding-top: 10%
    
  .columna-contacto
  
    padding-top: 9%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 31%
    
    .fila-boton
      padding-top: 8px
      padding-bottom: 10px
      display: flex
      justify-content: center
      .v-btn
          border-radius: 15px
        


.form
  width: 100%
  margin: 0px!important
  .v-text-field
    width: 30px
    height: 30px
    margin-bottom: 35px

.before-enter
  opacity: 0
  transform: translatex(-100%)
  transition: all 1.5s ease-out 
  &[card='true']
    transform: scale(.5) rotateZ(-25deg)
    transition: all 1s ease-out  
.enter
  opacity: 1
  transform: translateX(0px)
  &[card='true']
    transform: scale(1) rotateZ(0deg)
.texto_verde
    color: #00AA7F
   
.texto1
    color: #490383
    line-height: 1em
    margin-top: 30px
    font-size: 25px
.subtexto2
    color: #490383
    line-height: 0.6em
    margin-top: 30px
    font-size: 20px
</style>
    


